<template>
  <div>
    <load-more :data-source="list2" :total="total" :loading="loading" @load-more="onLoad2">
      <div style="margin-top:16px">
        <table width="100%">
          <tbody>
            <tr>
              <th>{{ headObj.th1 }}</th>
              <th>{{ headObj.th2 }}</th>
              <th>{{ headObj.th3 }}</th>
              <th>{{ headObj.th4 }}</th>
            </tr>
            <tr v-for="item in list2" :key="item.id">
              <td>{{ item.jcfycode }}</td>
              <td>{{ item.jcfymc }}</td>
              <td>次</td>
              <td>{{ item.money }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </load-more>
  </div>
</template>
<script>
import {constantModel} from '@/api/constant'
import loadMore from '@/assets/js/mixin/load-more'
export default {
   mixins: [loadMore],
  data(){
    return{
      list2:[],
      headObj:{
        th1:'编码',
        th2:'项目名称',
        th3: '单位',
        th4: '价格（元）'
      }
    }
  },
  mounted(){
    this.getserveList()
  },
  methods:{
    getserveList(){
      let params={
        page: this.page,
        pageSize: this.pageSize
      }
      constantModel.getServeList(params).then(res=>{
        this.list2.push(...(res.data || []))
        this.total = res.count || 0
        this.loading = false
      })
    },
     onLoad2(){
      this.loading=true
      this.page+=1
      this.getserveList()
    }
  }
}
</script>
<style scoped  lang='less'>
/deep/.van-list{
  padding: 0 !important;
}
.tipTxt{
  padding: 24px 32px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 40px;
  background: #DC3030;
  margin-bottom: 24px;
}
</style>
