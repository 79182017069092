<!--
 * @Date: 2023-03-13 16:11:34
 * @LastEditTime: 2023-08-24 16:04:16
 * @Description: 
 * @FilePath: \szlsnk-user-mp\src\views\price\pricePublicity.vue
-->
<template>
  <div>
    <van-tabs type="card" color="#3A8AE5">
      <van-tab title="药品价格标准">
        <load-more :data-source="list1" :total="total" :loading="loading" @load-more="onLoad">
          <price-table :head-obj="headData1" :data="list1" type="yao" />
        </load-more>
      </van-tab>
      <van-tab title="医疗服务价格标准">
        <service-price />
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import {constantModel} from '@/api/constant'
import loadMore from '@/assets/js/mixin/load-more'
import servicePrice from '@/views/price/servicePrice'
export default {
  name:'PricePublicity',
  components:{
    servicePrice
  },
  mixins: [loadMore],
  data(){
    return{
      list1:[],
      headData1:{
        th1:'药品名称',
        th2:'包装单位',
        th3: '药品规格',
        th4: '药品价格（元）'
      }
    }
  },
  mounted(){
    this.getList()
  },
  methods:{
    getList(){
      let params={
        page: this.page,
        pageSize: this.pageSize
      }
      constantModel.getPrice(params).then(res=>{
        this.list1.push(...(res.data || []))
        this.total = res.count || 0
        this.loading = false
      })
    },

    onLoad(){
      this.loading=true
      this.page+=1
      this.getList()
    }

  }
}
</script>
<style scoped  lang='less'>
/deep/.van-list{
  padding: 0 !important;
}
.tipTxt{
  padding: 24px 32px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 40px;
  background: #DC3030;
  margin-bottom: 24px;
}
</style>
